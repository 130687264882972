type Stats = {
  updatedAt: number;
  thankus: number;
  impact: {
    CleanOcean: number;
    PlantTrees: number;
    ProtectWildlife: number;
  };
};

async function showStats() {
  const $stats = document.getElementById("stats");
  const $date = document.getElementById("date");
  const $certificateCount = document.getElementById("certificateCount");
  const $treeCount = document.getElementById("treeCount");
  const $plasticCount = document.getElementById("plasticCount");
  const $habitatCount = document.getElementById("habitatCount");

  if (
    !$stats ||
    !$date ||
    !$certificateCount ||
    !$treeCount ||
    !$plasticCount ||
    !$habitatCount
  ) {
    return;
  }

  const data: Stats = await fetch("/api/totalStats").then((response) =>
    response.json()
  );

  $date.textContent = new Date(data.updatedAt).toLocaleDateString("en", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  $certificateCount.textContent = data.thankus.toLocaleString("en");
  $treeCount.textContent = data.impact.PlantTrees.toLocaleString("en");
  $plasticCount.textContent =
    data.impact.CleanOcean.toLocaleString("en") + " kg";
  $habitatCount.textContent =
    data.impact.ProtectWildlife.toLocaleString("en") + " sqm";
  $stats.classList.remove("opacity-0");
}

function infoOverlay() {
  const $info = document.getElementById("info");
  const $infoBtn = document.getElementById("infoBtn");
  const $closeInfoBtn = document.getElementById("closeInfoBtn");

  if (!$info || !$infoBtn || !$closeInfoBtn) return;

  $infoBtn.addEventListener("click", (event) => {
    event.preventDefault();
    $info.classList.toggle("hidden");
  });

  $closeInfoBtn.addEventListener("click", () => {
    $info.classList.add("hidden");
  });
}

showStats();
infoOverlay();
